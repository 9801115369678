import Vue from 'vue'
import VueRouter from 'vue-router'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Start',
    component: () =>
      import(/* webpackChunkName: "Start" */ '../views/Home.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },
  {
    path: '/observe',
    name: 'Observe',
    component: () =>
      import(/* webpackChunkName: "Observe" */ '../views/Observe.vue')
  },
  {
    path: '/capture',
    name: 'Capture',
    component: () =>
      import(/* webpackChunkName: "Capture" */ '../views/Capture.vue')
  },
  {
    path: '/docs',
    name: 'Table',
    component: () =>
      import(/* webpackChunkName: "TableView" */ '../views/TableView.vue')
  },
  {
    path: '/docs/capture/:id',
    name: 'Capture-edit',
    props: true,
    component: () =>
      import(/* webpackChunkName: "CaptureEdit" */ '../views/Capture.vue')
  },
  {
    path: '/docs/observe/:id',
    name: 'Observe-edit',
    props: true,
    component: () =>
      import(/* webpackChunkName: "ObserveEdit" */ '../views/Observe.vue')
  },
  {
    path: '/hash',
    name: 'Hash',
    props: true,
    component: () =>
      import(/* webpackChunkName: "Hash" */ '../views/HashView.vue')
  },
  {
    path: '/about',
    name: 'About',
    props: true,
    component: () =>
      import(/* webpackChunkName: "About" */ '../views/About.vue')
  },
  {
    path: '/import',
    name: 'Import',
    props: true,
    component: () =>
      import(/* webpackChunkName: "Import" */ '../views/Import.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
