/* eslint-disable no-unused-vars */
const _filter = require('lodash/filter')
const _sortBy = require('lodash/sortBy')
const _mapKeys = require('lodash/mapKeys')


import { EventBus } from '@/store/EventBus.js'
import hash from '@/lib/decryptPassword'
import uuid from 'short-uuid'
const PouchDB = require('pouchdb').default
let localDb = new PouchDB(process.env.VUE_APP_LOCAL_DB_NAME)
const username = process.env.VUE_APP_COUCH_DB_USERNAME
let remoteDb
let connected

const checkPassword = () => {
  console.log('Check if password is present')
  hash
    .decryptAndGetPassword(process.env.VUE_APP_COUCH_DB_PASSWORD_HASH)
    .then(password => {
      if (password) {
        clearInterval(checkIfHasPassword)
        connect()
      }
    })
    .catch(e => {
      console.log('Password is not present, trying again in 3 sec')
    })
}

let checkIfHasPassword = setInterval(checkPassword, 3000)

const connect = () => {
  hash
    .decryptAndGetPassword(process.env.VUE_APP_COUCH_DB_PASSWORD_HASH)
    .then(password => {
      try {
        remoteDb = new PouchDB(process.env.VUE_APP_COUCH_DB_URL, {
          auth: {
            username: username,
            password: password
          }
        })
        console.log('Connected to remote cloud')
        EventBus.$emit('success-connection-db')
      } catch (e) {
        console.log(e)
        EventBus.$emit('error-connection-db')
      }
    })
    .catch(e => {
      console.log(e)
      EventBus.$emit('error-connection-db')
    })
}
const ensureLowercaseKeys = (arr) => {
  let rows = arr.rows
  rows.forEach( (obj) => {
    obj.doc = _mapKeys(obj.doc, function (v, k) { return k.toLowerCase(); });
  })
  arr.rows = rows
  return arr
}

export default {
  replicateFromRemoteDb() {
    return new Promise((resolve, reject) => {
      localDb.replicate
        .from(remoteDb)
        .then(function(res) {
          console.log('Replication complete: ', res)
          resolve(res)
        })
        .catch(function(err) {
          console.log(err)
          reject(err)
        })
    })
  },
  resetLocalDB() {
    const self = this
    return new Promise((resolve, reject) => {
      localDb
        .destroy()
        .then(function(res) {
          console.log('Local database deleted: ', res)
          localDb = new PouchDB(process.env.VUE_APP_LOCAL_DB_NAME)
          self.replicateFromRemoteDb().then(function(res) {
            EventBus.$emit('update-from-server')
            resolve(res)
          })
        })
        .catch(function(err) {
          console.log(err)
          reject(err)
        })
    })
  },
  compactLocalDB() {
    return new Promise((resolve, reject) => { 
      localDb.compact().then( (res) => {
          resolve(res)
      }).catch(function (err) {
        reject(err)
      });
    })
  },

  compactRemoteDB() {
    return new Promise((resolve, reject) => { 
      remoteDb.compact().then( (res) => {
          resolve(res)
      }).catch(function (err) {
        reject(err)
      });
    })
  },
  
  deleteLocalDB() {
    localDb
      .destroy()
      .then(function(res) {
        console.log('Database deleted: ', res)
      })
      .catch(function(err) {
        console.log(err)
      })
  },
  localInfo() {
    return new Promise((resolve, reject) => {
      localDb
        .info()
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  remoteInfo() {
    return new Promise((resolve, reject) => {
      if (!remoteDb) {
        reject('No connection to remote cloud')
      }
      remoteDb
        .info()
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  post(doc) {
    doc._id = 'brrdsio:' + uuid.generate()
    doc.timestamp = new Date().toISOString()
    return new Promise((resolve, reject) => {
      localDb
        .put(doc)
        .then(function(res) {
          console.log('Post', doc)
          resolve(res)
        })
        .catch(function(err) {
          reject(err)
        })
    })
  },
  put(doc) {
    doc.timestamp = new Date().toISOString()
    return new Promise((resolve, reject) => {
      localDb
        .put(doc)
        .then(function(res) {
          console.log('Put', doc)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  delete(id) {
    return new Promise((resolve, reject) => {
      localDb
        .get(id)
        .then(function(doc) {
          doc._deleted = true
          localDb.put(doc)
          resolve(doc)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  get(id) {
    return new Promise((resolve, reject) => {
      localDb
        .get(id)
        .then(function(doc) {
          resolve(doc)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getAll() {
    return new Promise((resolve, reject) => {
      localDb
        .allDocs({ include_docs: true })
        .then(res => {
          res = ensureLowercaseKeys(res)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  findByType(type) {
    return new Promise((resolve, reject) => {
      localDb
        .allDocs({ include_docs: true })
        .then(res => {
          res = ensureLowercaseKeys(res)
          let result = _filter(res.rows, o => {
            return o.doc.type === type
          })
          result = this.sort(result)

          resolve(result)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  sort(arr) {
    let result = _sortBy(arr, o => {
      return o.timestamp
    })
    return result.reverse()
  },
  find(selector) {
    return new Promise((resolve, reject) => {
      localDb
        .allDocs({ include_docs: true })
        .then(res => {
          if (!selector.value) resolve([])
          res = ensureLowercaseKeys(res)
          let result = _filter(res.rows, o => {
            return (
              o.doc[selector.key] == selector.value &&
              o.doc.type == selector.type
            )
          })
          result = this.sort(result)
          resolve(result)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  sync() {
    return new Promise((resolve, reject) => {
      localDb
        .sync(remoteDb, {
          // live: true,
          retry: true,
        })
        .on('change', function(change) {
          console.log('Change: ', change)
          EventBus.$emit('update-from-server')
        })
        .on('paused', function(info) {
          console.log('Pause: ', info)
        })
        .on('active', function(info) {
          console.log('Active: ', info)
        })
        .on('denied', function(info) {
          console.log('Denied: ', info)
          reject(info)
        })
        .on('complete', function(info) {
          console.log('Complete: ', info)
          resolve(info)
        })
        .on('error', function(err) {
          console.log('Error: ', err)
          reject(err)
        })
    })
  }
}
