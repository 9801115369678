import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueOffline from 'vue-offline'
import vuetify from './plugins/vuetify'
import VueGeolocation from 'vue-browser-geolocation'
import Vuex from 'vuex'

Vue.use(Vuex)
Vue.use(VueOffline)
Vue.use(VueGeolocation)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
