<template>
  <v-app id="inspire">
    <section
      class="splash d-flex justify-center align-center"
      v-if="$route.name === 'Start'"
    >
      <Splash />
    </section>
    <section v-else>
      <SettingsDrawer :showDrawer="drawer" v-if="$route.name != 'About'" />
      <v-app-bar app>
        <v-icon
          color="primary"
          @click="$router.go(-1)"
          large
          v-show="$route.name != 'Home'"
          >mdi-chevron-left</v-icon
        >
        <v-toolbar-title class="ml-3">
          <h3>
            <span style="color:#4aa7bc">Brrds.</span>
            <span style="color:#0f171f">io</span>
          </h3>
          <h6 style="color:#BDBDBD" class="d-none d-sm-block">
            Better research registration database system
          </h6>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="mr-md-4 mr-xs-0">
          <span v-if="isOnline"
            ><v-icon color="primary" class="mr-3">mdi-wifi</v-icon></span
          >
          <span v-if="isOffline"
            ><v-icon color="primary" class="mr-3">mdi-wifi-off</v-icon></span
          >
        </div>
        <v-icon
          v-if="$route.name != 'About'"
          large
          color="primary"
          @click="drawer = !drawer"
          >mdi-menu</v-icon
        >
      </v-app-bar>

      <v-main>
        <router-view></router-view>
        <div style="min-height: 50px"></div>
        <SystemBar />
      </v-main>

      <NotifyUserPopup />
      <CloudSyncDialog />
    </section>
    <v-snackbar
      class="mt-20"
      v-model="snackRefreshUI"
      top
      centered
      timeout="-1"
      style="z-index: 99999999"
    >
      <v-icon color="primary " class="mr-2">mdi-party-popper</v-icon> A new
      version of Brrds.io available!
      <template v-slot:action="{ attrs }">
        <v-btn text color="primary" v-bind="attrs" @click.stop="refreshApp">
          Refresh
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import SystemBar from '@/components/SystemBar.vue'
import SettingsDrawer from '@/components/SettingsDrawer.vue'
import NotifyUserPopup from '@/components/NotifyUserPopup.vue'
import CloudSyncDialog from '@/components/CloudSyncDialog.vue'
import Splash from '@/components/Splash.vue'

export default {
  data: () => ({
    drawer: false,
    refreshing: false,
    registration: null,
    snackBtnText: '',
    snackWithBtnText: '',
    snackRefreshUI: false
  }),
  methods: {
    goHome() {
      this.$router.push({ name: 'Home' })
    },
    showRefreshUI(e) {
      this.registration = e.detail
      this.snackRefreshUI = true
    },
    refreshApp() {
      this.snackRefreshUI = false
      if (!this.registration || !this.registration.waiting) {
        return
      }
      this.registration.waiting.postMessage('skipWaiting')
    }
  },
  created() {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      })
    }
  },
  components: {
    SystemBar,
    SettingsDrawer,
    NotifyUserPopup,
    CloudSyncDialog,
    Splash
  }
}
</script>

<style scoped>
.splash {
  height: 100vh;
}
.text-start {
  color: red;
}
</style>
