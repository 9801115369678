import CryptoJS from 'crypto-js'
export default {
  decryptAndGetPassword(hash) {
    return new Promise((resolve, reject) => {
      const secretKey = localStorage.getItem('secretKey')
      if (!secretKey) {
        reject('Password not correct.')
      }
      try {
        const bytes = CryptoJS.AES.decrypt(hash, secretKey)
        const originalPassword = bytes.toString(CryptoJS.enc.Utf8)
        if (!originalPassword) {
          reject('Password not correct.')
        } else {
          resolve(originalPassword)
        }
      } catch (e) {
        reject(reject('Password not correct.'))
      }
    })
  },
}
