<template>
  <v-card class="p-6">
    <v-card-title class="headline">
      Login
    </v-card-title>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-text-field v-model="username" label="Your name"></v-text-field>
          <v-text-field
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            v-model="secretKey"
            label="Secret key"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-btn
            x-large
            class="float-right"
            color="primary"
            @click="saveUserName"
            :disabled="!isUsernameOK"
          >
            Sign in
            <v-icon class="ml-2">mdi-login</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <div class="copyright justify-center align-center w-100 mt-5">
        <div>
          <span>Do you want to know more Brrds.io? </span>
          <span @click="$router.push({ name: 'About' })" class="copyright-link"
            >Click here</span
          >
        </div>
      </div>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      hasUserName: false,
      username: '',
      secretKey: '',
      showPassword: false
    }
  },
  mounted() {
    this.checkUsername()
  },
  computed: {
    isUsernameOK() {
      return this.username.length > 3
    }
  },
  methods: {
    checkUsername() {
      if (localStorage.getItem('username')) {
        this.username = localStorage.getItem('username')
        this.secretKey = localStorage.getItem('secretKey')
        if (this.username.length > 3) {
          this.hasUserName = true
        } else {
          this.hasUserName = false
        }
      } else {
        this.hasUserName = false
      }
    },
    saveUserName() {
      localStorage.setItem('username', this.username)
      localStorage.setItem('secretKey', this.secretKey)
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="scss" scoped>
.copyright {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  color: #0f171f;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  // padding: 10px;
}
.copyright span {
  opacity: 0.3;
}
.copyright-link {
  opacity: 0.7 !important;
  cursor: pointer;
}
</style>
