<template>
  <v-dialog v-model="dialog" scrollable width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-on="on" v-bind="attrs">
        <v-list-item-icon>
          <v-icon>mdi-key-change</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Replace Acrylic ID</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>Replace Acrylic ID</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              :autofocus="false"
              v-model="oldAcrylic"
              label="Old acrylic ID"
              hint="The format should be ´G###´"
              outlined
              append-outer-icon="mdi-arrow-right-bold"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              :autofocus="false"
              v-model="newAcrylic"
              label="New acrylic ID"
              hint="The format should be ´G###´"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            {{ msg }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn x-large color="primary" @click="close">
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn x-large color="primary" :loading="loading" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable no-unused-vars */
import Pouch from '@/store/Pouch.js'

export default {
  name: 'ChangeDarvic',
  components: {},
  data() {
    return {
      dialog: false,
      oldAcrylic: '',
      newAcrylic: '',
      msg: '',
      loading: false
    }
  },
  methods: {
    async save() {
      this.loading = true
      this.oldAcrylic = this.oldAcrylic.toUpperCase()
      this.newAcrylic = this.newAcrylic.toUpperCase()
      let captures = await Pouch.findByType('capture')
      captures = captures.filter(row => row.doc.acrylic === this.oldAcrylic)
      captures.forEach(row => {
        row.doc.acrylic = this.newAcrylic
        Pouch.put(row.doc)
      })

      let observes = await Pouch.findByType('observe')
      observes = observes.filter(row => row.doc.acrylic === this.oldAcrylic)
      observes.forEach(row => {
        row.doc.acrylic = this.newAcrylic
        Pouch.put(row.doc)
      })
      this.msg = `Replaced the Acrylic ID on ${captures.length +
        observes.length} bird(s) in the database. Don't forget to sync it with the cloud`
      this.loading = false
    },
    close() {
      this.oldAcrylic = ''
      this.newAcrylic = ''
      this.msg = ''
      this.dialog = false
    }
  }
}
</script>

<style scoped></style>
