<template>
  <div class="splash">
    <div class="d-flex flex-column justify-center align-center logo">
      <h3>
        <span style="color: rgb(74, 167, 188);">Brrds.</span
        ><span style="color: rgb(255, 255, 255);">io</span>
      </h3>
      <v-progress-circular
        v-if="loading"
        indeterminate
        size="35"
        color="primary"
      ></v-progress-circular>
      <div
        transition="fade-transition"
        class="login-panel mt-5"
        v-if="!loading"
      >
        <Login />
      </div>
    </div>
  </div>
</template>

<script>
import Login from '@/components/Login.vue'

export default {
  name: 'Splash',
  data() {
    return {
      loading: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 4000)
  },

  components: {
    Login
  }
}
</script>

<style scoped>
.login-panel {
  z-index: 99999;
  max-width: 300px;
}
.splash {
  background-image: url('/img/splash-min.jpg');
  background-position: bottom;
  background-repeat: no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
}
.logo {
  z-index: 888;
  height: 100%;
}
h3 {
  font-size: 3rem;
}
</style>
