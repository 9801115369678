<template>
  <v-dialog v-model="dialog" scrollable width="700px">
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-btn v-bind="attrs" v-on="on" icon class="mr-2 mr-5">
        <v-icon color="primary" v-if="$route.name == 'Home'" large
          >mdi-application-export</v-icon
        >
      </v-btn> -->
      <v-list-item v-on="on" v-bind="attrs">
        <v-list-item-icon>
          <v-icon>mdi-application-export</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Export Excel-file</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>Export Excel-file</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-radio-group v-model="whatToExport" column>
          <v-radio label="Export All data" value="all"></v-radio>
          <v-radio label="Export Captures" value="capture"></v-radio>
          <v-radio label="Export Observations" value="observe"></v-radio>
          <v-radio label="Export Resighting" value="resighting"></v-radio>
        </v-radio-group>
        <Filters @filterUpdate="filterUpdateHandler" />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn x-large color="primary" @click="close">
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn x-large color="primary" @click="exportData" :loading="loading">
          Save file
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable no-unused-vars */

import XLSX from 'xlsx'
import Pouch from '@/store/Pouch.js'
import _uniq from 'lodash/uniq'
import _uniqBy from 'lodash/uniqBy'
import _filter from 'lodash/filter'
import _sortBy from 'lodash/sortBy'
import _countBy from 'lodash/countBy'
import Filters from '@/components/Filters.vue'
import { getResighting } from '@/lib/buildExcelResighting.js'
export default {
  name: 'ExportExcel',
  components: { Filters },
  data() {
    return {
      whatToExport: 'all',
      dialog: false,
      currentData: [],
      filters: [],
      loading: false
    }
  },
  beforeDestroy() {
    this.filters = null
    this.whatToExport = 'all'
  },
  methods: {
    close() {
      this.whatToExport = 'all'
      this.dialog = false
      this.currentData = []
      this.filters = []
      this.loading = false
      this.dialog = false
    },
    filterUpdateHandler(arrFilters) {
      this.filters = arrFilters
    },

    getAvailableYears(data) {
      let years = []
      data.forEach(row => {
        let year = new Date(row.date).getFullYear()
        if (!isNaN(year) && year !== 1970) {
          years.push(year)
        }
      })
      years = _uniq(years)
      years = _sortBy(years, year => year)
      return years
    },
    convertBreedingColumn(data) {
      data.forEach(row => {
        switch (row.breeder) {
          case 'not breeding':
            row.breeder = 0
            break
          case 'bird on empty nest':
            row.breeder = 1
            break
          case 'bird not on a nest':
            row.breeder = 1
            break
          case 'egg':
            row.breeder = 2
            break
          case 'chick':
            row.breeder = 2
            break
        }
      })
      return data
    },
    convertLoggersToColumns(data) {
      data.forEach(row => {
        let loggers = row.loggers
        if (row.loggers) {
          loggers.forEach(log => {
            row[`Logger ${log.id} type`] = log.type
            row[`Logger ${log.id} status`] = log.status
            row[`Logger ${log.id} data`] = log.data
          })
        }
        delete row.loggers
      })
      return data
    },
    cleanUp(data) {
      data.forEach(row => {
        delete row._id
        delete row._rev
      })
      return data
    },
    async getAllData(type) {
      let data = []
      let res = await Pouch.findByType(type)
      res.forEach(row => {
        data.push(row.doc)
      })

      this.filters.forEach(filter => {
        console.log('Filter by: ', filter.key)
        let key = filter.key
        let selectedValue = filter.selectedValue
        data = filter.fn.call({ key, selectedValue }, data)
      })
      return data
    },
    filterDataByYear(data, targetYear) {
      const rows = _filter(data, row => {
        let year = new Date(row.date).getFullYear()
        if (year == targetYear) {
          return true
        }
      })
      return rows
    },
    async exportData() {
      this.loading = true
      if (this.whatToExport === 'all') {
        await this.buildExcelFile('capture')
        await this.buildExcelFile('observe')
        await this.buildExcelFileResighting()
      } else if (this.whatToExport === 'capture') {
        await this.buildExcelFile('capture')
      } else if (this.whatToExport === 'observe') {
        await this.buildExcelFile('observe')
      } else if (this.whatToExport === 'resighting') {
        await this.buildExcelFileResighting()
      }
      this.loading = false
      this.dialog = false
    },
    async buildExcelFileResighting() {
      return new Promise(resolve => {
        let data = []
        let promiseCaptures = this.getAllData('capture')
        let promiseObserves = this.getAllData('observe')

        Promise.all([promiseCaptures, promiseObserves]).then(values => {
          let captures = values[0]
          let observes = values[1]
          let result
          getResighting(captures, observes)
            .then(res => {
              result = res
              // console.table(result)
              let wb = XLSX.utils.book_new()
              let dataTable = XLSX.utils.json_to_sheet(result)
              XLSX.utils.book_append_sheet(wb, dataTable, `Resightings`)

              XLSX.writeFile(
                wb,
                `${new Date().toISOString()}-brrds-Resightings.xlsx`
              )
              resolve()
            })
            .catch(err => {
              alert(err.toString())
            })
        })
      })
    },
    buildExcelFile(type) {
      return new Promise(resolve => {
        let res = this.getAllData(type)
        res
          .then(data => {
            if (!data) {
              resolve()
              return
            }
            if (type === 'capture') {
              data = this.convertLoggersToColumns(data)
            }
            if (type === 'observe') {
              data = this.convertBreedingColumn(data)
            }
            data = this.cleanUp(data)
            let wb = XLSX.utils.book_new()
            const years = this.getAvailableYears(data)
            years.forEach(year => {
              let yearlyData = this.filterDataByYear(data, year)
              let dataTable = XLSX.utils.json_to_sheet(yearlyData)
              XLSX.utils.book_append_sheet(wb, dataTable, `${year}`)
            })
            XLSX.writeFile(wb, `${new Date().toISOString()}-brrds-${type}.xlsx`)
            resolve()
            return
          })
          .catch(err => {
            alert(err.toString())
          })
      })
    }
  }
}
</script>

<style scoped></style>
