<template>
  <v-navigation-drawer v-model="drawer" app right>
    <v-list>
      <v-list-item-group v-model="model" color="primary">
        <v-list-item
          v-for="(item, i) in items"
          @click="routeTo(item.route)"
          :key="i"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <ExportExcel />
      <ChangeDarvic />

      <v-list-item @click="routeTo('About')">
        <v-list-item-icon>
          <v-icon>mdi-sparkles</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>About Brrds.io</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item @click="signout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Sign out</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { EventBus } from '@/store/EventBus.js'
import ExportExcel from '@/components/ExportExcel.vue'
import ChangeDarvic from '@/components/ChangeDarvic.vue'

import Pouch from '@/store/Pouch.js'
export default {
  name: 'SettingsDrawer',
  props: ['showDrawer'],
  components: { ExportExcel, ChangeDarvic },
  data() {
    return {
      username: null,
      drawer: false,
      resetting: false,
      items: [
        {
          icon: 'mdi-home',
          text: 'Home',
          route: 'Home'
        },
        {
          icon: 'mdi-hand',
          text: 'Capture and measure',
          route: 'Capture'
        },
        {
          icon: 'mdi-binoculars',
          text: 'Observe',
          route: 'Observe'
        },
        {
          icon: 'mdi-table',
          text: 'View and edit data',
          route: 'Table'
        },
        {
          icon: 'mdi-database-settings',
          text: 'Cloud sync',
          route: 'sync-dialog'
        }
      ],
      model: 0
    }
  },
  watch: {
    showDrawer() {
      this.drawer = !this.showDrawer
    }
  },
  mounted() {},
  methods: {
    signout() {
      localStorage.removeItem('username')
      localStorage.removeItem('secretKey')
      window.location = '/'
    },
    routeTo(route) {
      this.drawer = false
      if (route === 'sync-dialog') {
        EventBus.$emit('show-sync-dialog')
      } else {
        this.$router.push({ name: route })
      }
    },
    async resetDB() {
      this.resetting = true
      await Pouch.resetLocalDB()
      this.resetting = false
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped></style>
