<template>
  <div>
    <v-dialog v-model="showSyncDialog" persistent width="800">
      <v-card>
        <v-card-title class="headline">
          Cloud sync
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="5">
              <h3>
                Local database<v-btn
                  style="float: right;"
                  text
                  :tile="true"
                  x-small
                  plain
                  @click="compact"
                  :loading="isCompressing"
                  >Compress database
                </v-btn>
              </h3>
              <v-textarea
                filled
                name="input-7-4"
                v-model="localDbInfo"
              ></v-textarea>
            </v-col>
            <v-col cols="2" align-center text-center>
              <div class="d-flex align-center justify-center">
                <h3></h3>

                <v-btn
                  class="mt-5"
                  x-large
                  color="green darken-1"
                  @click="sync"
                  :loading="syncing"
                  :disabled="syncing || isOffline"
                  >Sync</v-btn
                >
              </div>
            </v-col>
            <v-col cols="5">
              <h3>
                The Cloud
              </h3>
              <div></div>
              <v-textarea
                filled
                name="input-7-4"
                v-if="isOnline"
                v-model="remoteDbInfo"
              ></v-textarea>
              <v-textarea
                class="body-2"
                filled
                name="input-7-4"
                v-if="isOffline"
                value="Device is not connected to the internet, syncing not possible at this moment"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-banner single-line v-if="syncInfo">
                <v-icon slot="icon" color="success" size="36">
                  mdi-thumb-up-outline
                </v-icon>
                <p class="subtitle-2">
                  You pulled {{ syncInfo.pull.docs_written }} from the remote
                  cloud.<br />
                  You pushed {{ syncInfo.push.docs_written }} to the remote
                  cloud.
                </p>
              </v-banner>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            x-large
            @click="
              showSyncDialog = false
              syncInfo = null
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="showSnackbar"
      multi-line
      max-width="200px"
      timeout="5000"
      bottom
      left
      style="z-index: 99999999"
    >
      You have local changes in the database. <br />Do you want to sync the data
      with the cloud?
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
        <v-btn color="green" text v-bind="attrs" @click="_showDialog">
          Yes
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Pouch from '@/store/Pouch.js'
import { EventBus } from '@/store/EventBus.js'

export default {
  data() {
    return {
      showSnackbar: false,
      showSyncDialog: false,
      remoteDbInfo: null,
      localDbInfo: null,
      syncInfo: null,
      syncing: false,
      isCompressing: false
    }
  },
  mounted() {
    EventBus.$on('show-sync-dialog', () => {
      this.showSnackbar = false
      this.showSyncDialog = true
      this.getLocalDbInfo()
      this.getRemoteDbInfo()
    })
    EventBus.$on('show-sync-snackbar', () => {
      if (this.isOnline) {
        this.showSnackbar = true
      }
    })
  },
  showSyncDialog(visible) {
    if (visible) {
      this.getLocalDbInfo()
      this.getRemoteDbInfo()
    }
  },
  methods: {
    async compact() {
      this.isCompressing = true
      let res = await Pouch.compactLocalDB()
      this.isCompressing = false
      const msg = 'Compressing database: ' + JSON.stringify(res)
      alert(msg)
    },
    _showDialog() {
      this.showSnackbar = false
      this.showSyncDialog = true
    },
    async sync() {
      try {
        this.syncing = true
        this.syncInfo = null
        let res = await Pouch.sync()
        console.log(res)
        this.syncInfo = res
      } catch (e) {
        alert(e)
      }
      this.syncing = false
    },
    async getRemoteDbInfo() {
      let res
      try {
        res = await Pouch.remoteInfo()
        res = Object.fromEntries(Object.entries(res).sort())
        this.remoteDbInfo = JSON.stringify(res, null, 2)
      } catch (e) {
        this.remoteDbInfo = e
      }
    },
    async getLocalDbInfo() {
      let res
      try {
        res = await Pouch.localInfo()
        res = Object.fromEntries(Object.entries(res).sort())
        this.localDbInfo = JSON.stringify(res, null, 2)
      } catch (e) {
        this.localDbInfo = e
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
