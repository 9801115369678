/* eslint-disable no-unused-vars */
import _filter from 'lodash/filter'
import _unionBy from 'lodash/unionBy'

let Filters = [
  {
    key: 'dead',
    text: 'Dead',
    multiple: false,
    possibleValues: [true, false],
    active: false,
    selectedValue: null,
    fn: function(data) {
      return data.filter(row => row[this.key] == this.selectedValue)
    }
  },
  {
    key: 'location',
    text: 'Location',
    multiple: true,
    possibleValues: [
      'Main Colony East (MCE)',
      'Main Colony South (MCS)',
      'Above Campsite (AC)',
      'North Valley (NV)',
      'North Valley West (NVW)',
      'Other'
    ],
    active: false,
    selectedValue: null,
    fn: function(data) {
      return data.filter(row => {
        if (row[this.key] && this.selectedValue) {
          return this.selectedValue.includes(row[this.key])
        }
      })
    }
  },
  {
    key: 'sex',
    text: 'Sex',
    possibleValues: ['Unknown', 'M', 'F'],
    multiple: false,
    active: false,
    selectedValue: null,
    fn: function(data) {
      if (this.selectedValue === 'Unknown') {
        return data.filter(row => row[this.key] != 'M' && row[this.key] != 'F')
      } else {
        return data.filter(row => row[this.key] == this.selectedValue)
      }
    }
  },
  {
    key: 'loggers',
    text: 'Logger Types',
    possibleValues: ['GPS', 'GLS', 'Camera', 'TDR', 'Accelerometer'],
    multiple: true,
    active: false,
    selectedValue: null,
    fn: function(data) {
      let res = []
      this.selectedValue.forEach(val => {
        res = _unionBy(res, _filter(data, { loggers: [{ type: val }] }), '_id')
      })
      return res
    }
  },
  {
    key: 'acrylic',
    text: 'Acrylic ID',
    possibleValues: null,
    active: false,
    selectedValue: null,
    multiple: false,
    fn: function(data) {
      return data.filter(row => {
        if (row[this.key]) {
          return row[this.key].toLowerCase() == this.selectedValue.toLowerCase()
        }
      })
    }
  },
  {
    key: 'date',
    text: 'Year',
    possibleValues: null,
    active: false,
    selectedValue: null,
    multiple: false,
    fn: function(data) {
      return data.filter(row => {
        if (row[this.key]) {
          return row[this.key].includes(this.selectedValue)
        }
      })
    }
  }
]

export { Filters }
