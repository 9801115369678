<template>
  <v-snackbar
    v-model="snackbar"
    bottom
    right
    timeout="3000"
    style="z-index: 99999999"
  >
    {{ val }}
    <template v-slot:action="{ attrs }">
      <v-btn text color="primary" v-bind="attrs" @click="snackbar = false">
        dismiss
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { EventBus } from '@/store/EventBus.js'
export default {
  data() {
    return {
      snackbar: false,
      val: ''
    }
  },
  mounted() {
    EventBus.$on('notify-user', val => {
      if (val === 'Saved') {
        EventBus.$emit('show-sync-snackbar')
      }
      this.val = val
      this.snackbar = true
    })
  }
}
</script>

<style lang="scss" scoped></style>
