/* eslint-disable no-unused-vars */
import _uniqBy from 'lodash/uniqBy'
import _sortBy from 'lodash/sortBy'
import _uniq from 'lodash/uniq'
import _filter from 'lodash/filter'

let captures, observes

const sort = arr => {
  let result = _sortBy(arr, o => {
    return o.acrylic
  })
  return result
}

const getUniqBirdsBy = (data, by) => {
  let res = _uniqBy(data, by)
  return res
}

const getAvailableYears = () => {
  let years = []
  let data = [].concat(captures, observes)
  data.forEach(row => {
    let year = new Date(row.date).getFullYear()
    if (!isNaN(year) && year !== 1970) {
      years.push(year)
    }
  })
  years = _uniq(years)
  years = _sortBy(years, year => year)
  return years
}

const determinSex = bird => {
  let sex = null
  let birds
  birds = _filter(captures, c => {
    return c.bto === bird.bto
  })
  for (let b of birds) {
    if (b.sex) {
      sex = b.sex
      break
    }
  }
  if (!sex) {
    birds = _filter(captures, c => {
      return c.acrylic === bird.acrylic
    })
    for (let b of birds) {
      if (b.sex) {
        sex = b.sex
        break
      }
    }
  }
  return sex
}

const getBreederStatus = bird => {
  if (bird.breeder === true) {
    return 'B'
  } else if (bird.breeder === false) {
    return 'NB'
  } else {
    return null
  }
}
const getLocation = bird => {
  if (bird.location === 'Other') {
    return bird.other_location
  } else {
    return bird.location
  }
}
const isBirdSeenInYear = (bird, year) => {
  let allBirds = [].concat(captures, observes)
  const seen = 1
  const notSeen = 0
  let filterBirds
  // First try with bto-id
  filterBirds = _filter(allBirds, b => {
    if (b.date && b.bto !== null) {
      let sightYear = new Date(b.date).getFullYear()
      return b.bto === bird.bto && year === sightYear
    } else {
      return false
    }
  })
  if (filterBirds.length > 0) {
    return seen
  }
  // Then try with acrylic-id
  filterBirds = _filter(allBirds, b => {
    if (b.date && b.acrylic !== null) {
      let sightYear = new Date(b.date).getFullYear()
      return b.acrylic === bird.acrylic && year === sightYear
    } else {
      return false
    }
  })
  if (filterBirds.length > 0) {
    return seen
  } else {
    return notSeen
  }
}

const addCMRstring = (row, years) => {
  let CMRstring = ''
  for (let year of years) {
    CMRstring = CMRstring.concat(row[year])
  }
  return CMRstring + ' 1;'
}

const getResighting = (dataCaptures, dataObserves) => {
  captures = dataCaptures
  observes = dataObserves
  const availableYears = getAvailableYears()
  return new Promise(resolve => {
    let result = []
    let capturesByBto = getUniqBirdsBy(dataCaptures, 'bto')
    let capturesByAcrylic = getUniqBirdsBy(dataCaptures, 'acrylic')

    let observesByBto = getUniqBirdsBy(dataObserves, 'bto')
    let observesByAcrylic = getUniqBirdsBy(dataObserves, 'acrylic')

    let birds = [].concat(
      capturesByBto,
      capturesByAcrylic,
      observesByBto,
      observesByAcrylic
    )

    birds = _uniq(birds, x => x._id)
    birds.forEach(bird => {
      let row = {
        YEAR_FIRST_MARKED: null,
        BTO: bird.bto,
        ACRYLIC: bird.acrylic,
        SEX: determinSex(bird),
        'SUB COLONY': getLocation(bird),
        STATUS: getBreederStatus(bird)
      }
      availableYears.forEach(year => {
        let seenStatus = isBirdSeenInYear(bird, year)
        if (seenStatus === 1 && !row.YEAR_FIRST_MARKED) {
          row.YEAR_FIRST_MARKED = year
        }
        row[year] = seenStatus
      })
      row.CMR = addCMRstring(row, availableYears)
      result.push(row)
    })
    resolve(sort(result))
  })
}

export { getResighting }
