/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dataTableCurrentSection: 0, // 0 = capture, 1 = observation
    dataTablePage: 1,
    search: '',
    sort: ['date'],
    sortDesc: [true]
  },
  getters: {
    getCurrentPage: state => {
      return state.dataTablePage
    },
    getCurrentSection: state => {
      return state.dataTableCurrentSection
    }
  },
  mutations: {
    SET_SORT(state, colName) {
      state.sort = colName
    },
    SET_SORT_DESC(state, status) {
      state.sortDesc = status
    },
    SET_PAGE(state, pageNr) {
      state.dataTablePage = pageNr
    },
    SET_SECTION(state, section) {
      state.dataTableCurrentSection = section
    },
    SET_SEARCH(state, val) {
      state.search = val
    },
    RESET_ALL(state) {
      state.dataTableCurrentSection = 0
      state.dataTablePage = 1
      state.search = ''
      state.sort = ['date']
      state.sortDesc = [true]
    }
  },
  actions: {
    setPage({ commit }, pageNr) {
      commit('SET_PAGE', pageNr)
    }
  },
  modules: {}
})
