<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th></th>
          <th class="text-left">
            Filter
          </th>
          <th class="text-left">
            Value
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="filter in filters" :key="filter.key">
          <td>
            <v-checkbox v-model="filter.active"></v-checkbox>
          </td>
          <td :class="{ 'text--disable': !filter.active }">
            {{ filter.text }}
          </td>
          <td v-if="filter.possibleValues">
            <v-select
              align="center"
              :disabled="!filter.active"
              :items="filter.possibleValues"
              v-model="filter.selectedValue"
              :multiple="filter.multiple"
            ></v-select>
          </td>
          <td v-if="!filter.possibleValues">
            <v-text-field
              class="mt-3"
              :label="filter.text"
              v-model="filter.selectedValue"
              filled
              :disabled="!filter.active"
            ></v-text-field>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import _filter from 'lodash/filter'
import { Filters } from '@/lib/Filters.js'
export default {
  data() {
    return {
      filters: Filters
    }
  },
  watch: {
    filters: {
      handler: 'filterEventHandler',
      deep: true
    }
  },
  methods: {
    filterEventHandler() {
      let filters = _filter(this.filters, o => {
        return o.active === true
      })
      this.$emit('filterUpdate', filters)
    }
  }
}
</script>

<style scoped>
.text--disable {
  opacity: 0.5;
}
</style>
