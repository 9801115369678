<template>
  <div class="system-bar">
    <v-system-bar
      dark
      color="primary"
      class="pr-2"
      style="position: unset; height: 24px;"
    >
      <span class="clickable-text" @click="showSyncDialog">Local DB:</span>
      <span v-if="localDocCount"> {{ localDocCount }} </span>
      <v-progress-circular
        class="ml-2"
        v-if="!localDocCount"
        :width="1"
        :size="13"
        color="white"
        indeterminate
      ></v-progress-circular>

      <span class="mx-2">/</span>
      <span class="clickable-text" @click="showSyncDialog">Cloud DB:</span>
      <span v-if="remoteDocCount" v-html="remoteDocCount"></span>
      <v-progress-circular
        class="ml-2"
        v-if="!remoteDocCount"
        :width="1"
        :size="13"
        color="white"
        indeterminate
      ></v-progress-circular>
      <v-spacer></v-spacer>
      <v-icon v-if="isOffline" color="#fff">mdi-wifi-off</v-icon>
      <v-icon v-if="isOnline" color="#fff">mdi-wifi</v-icon>
      <span class="pr-2"></span>
    </v-system-bar>
  </div>
</template>

<script>
import { EventBus } from '@/store/EventBus.js'
import Pouch from '@/store/Pouch.js'
export default {
  name: 'SystemBar',
  data() {
    return {
      localDocCount: null,
      remoteDocCount: null,
      pollHandler: null
    }
  },
  mounted() {
    this.pollHandler = setInterval(this.updateDbInfo, 5000)
    EventBus.$on('error-connection-db', () => {
      this.remoteDocCount =
        ' <span style="color:red; opacity:.7">Unauthorized, no connection to remote cloud</span>'
    })
    EventBus.$on('success-connection-db', () => {
      this.getRemoteDbInfo()
    })

    this.$on('online', () => {
      console.log('start poll')
      this.getRemoteDbInfo()
      this.pollHandler = setInterval(this.updateDbInfo, 5000)
    })
    this.$on('offline', () => {
      console.log('stop poll')
      this.remoteDocCount = ' No connection to remote cloud'
      clearInterval(this.pollHandler)
    })
  },
  methods: {
    showSyncDialog() {
      EventBus.$emit('show-sync-dialog')
    },
    updateDbInfo() {
      this.getLocalDbInfo()
      this.getRemoteDbInfo()
    },
    async getLocalDbInfo() {
      let res = await Pouch.localInfo()
      this.localDocCount = res.doc_count.toString()
    },
    async getRemoteDbInfo() {
      try {
        let res = await Pouch.remoteInfo()
        this.remoteDocCount = res.doc_count.toString()
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.system-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
}
.clickable-text {
  cursor: pointer;
}
</style>
